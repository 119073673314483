// @mui material components
import Card from "@mui/material/Card";

// cimPL Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// cimPL Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
import authorsTableData from "layouts/users-and-clients/data/authorsTableData";
import projectsTableData from "layouts/users-and-clients/data/projectsTableData";
import SoftButton from "components/SoftButton";

function Users() {
  const { columns, rows } = authorsTableData;
  const { columns: prCols, rows: prRows } = projectsTableData;

  const openUsersPortal = () => {
    window.open('https://users.cimpress.io/', '_blank', 'noopener')
  }

  const openClientPortal = () => {
    window.open('https://access.cimpress.io/account-management/accounts/g2Ez5VaoZWoqU22XqPjTLU#clients/', '_blank', 'noopener')
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
            >
              <SoftTypography variant="h6">Users</SoftTypography>
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table columns={columns} rows={rows} />
            </SoftBox>

            <SoftButton color="black" onClick={openUsersPortal}>Manage Users</SoftButton>
          </Card>
        </SoftBox>
        <Card>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            <SoftTypography variant="h6">Clients</SoftTypography>
          </SoftBox>
          <SoftBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            <Table columns={prCols} rows={prRows} />
          </SoftBox>
          <SoftButton color="black" onClick={openClientPortal}>Manage Clients</SoftButton>
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Users;
