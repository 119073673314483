/** 
  All of the routes for the cimPL React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// cimPL Dashboard React layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
// import RTL from "layouts/rtl";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import LandingPage from "layouts/landing-page";
import Product from "layouts/products";
// cimPL Dashboard React icons
import Shop from "examples/Icons/Shop";
import Office from "examples/Icons/Office";
import Settings from "examples/Icons/Settings";
import Document from "examples/Icons/Document";
import SpaceShip from "examples/Icons/SpaceShip";
import CustomerSupport from "examples/Icons/CustomerSupport";
import CreditCard from "examples/Icons/CreditCard";
import Basket from "examples/Icons/Basket";
import Cube from "examples/Icons/Cube";
import Users from "layouts/users-and-clients";
import Resellers from "layouts/resellers";
import Store from "layouts/Stores";
import Templates from "layouts/templates";
import Order from "layouts/Orders";
import Memberships from "layouts/membership";
import Partner from "layouts/Partner";

const routes = [
  {
    type: "collapse",
    name: "Home",
    key: "home",
    route: "/",
    icon: <Office size="12px" />,
    component: <LandingPage />,
    noCollapse: true,
    authenticted: false,
  },
  {
    type: "collapse",
    name: "Home",
    key: "home",
    route: "/home",
    icon: <Office size="12px" />,
    component: <LandingPage />,
    noCollapse: true,
    authenticted: false,
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <SpaceShip size="12px" />,
    component: <Dashboard />,
    noCollapse: true,
    authenticted: true,
  },

  {
    type: "title",
    title: "Members",
    key: "members",
    authenticted: true,
  },
  {
    type: "collapse",
    name: "Users & Clients",
    key: "users",
    route: "/users",
    icon: <Cube size="12px" />,
    component: <Users />,
    noCollapse: true,
    authenticted: true,
  },
  {
    type: "collapse",
    name: "Resellers",
    key: "resellers",
    route: "/resellers",
    icon: <Office size="12px" />,
    component: <Resellers />,
    noCollapse: true,
    authenticted: true,
  },

  {
    type: "title",
    title: "Account & Access",
    key: "account-management",
    authenticted: true,
  },
  {
    type: "collapse",
    name: "Accounts",
    key: "accounts",
    route: "/accounts",
    href: 'https://accounts.cimpress.io/',
    icon: <Office size="12px" />,
    noCollapse: true,
    authenticted: true,
  },
  {
    type: "collapse",
    name: "Access UI",
    key: "access",
    route: "/access",
    href: 'https://access.cimpress.io/',
    icon: <Office size="12px" />,
    noCollapse: true,
    authenticted: true,
  },

  {
    type: "title",
    title: "Product Management",
    key: "product-management",
    authenticted: true,
  },

  {
    type: "collapse",
    name: "Products",
    key: "products",
    route: "/products",
    icon: <Cube size="12px" />,
    component: <Product />,
    noCollapse: true,
    authenticted: true,
  },
  {
    type: "collapse",
    name: "Product Catalog",
    key: "product-catalog",
    route: "/product-catalog",
    href: 'https://marketplace.products.cimpress.io/search',
    icon: <Cube size="12px" />,
    noCollapse: true,
    authenticted: true,
  },
  {
    type: "collapse",
    name: "Product Templates",
    key: "product-templates",
    route: "/product-templates",
    icon: <Cube size="12px" />,
    component: <Templates />,
    noCollapse: true,
    authenticted: true,
  },
  {
    type: "collapse",
    name: "Stores",
    key: "stores",
    route: "/stores",
    icon: <Shop size="12px" />,
    component: <Store />,
    noCollapse: true,
    authenticted: true,
  },

  {
    type: "title",
    title: "Fulfillment",
    key: "fulfillment",
    authenticted: true,
  },
  {
    type: "collapse",
    name: "Fulfillers",
    key: "fulfillers",
    route: "/fulfillers",
    href: "https://supplierprofile.cimpress.io/",
    icon: <Office size="12px" />,
    noCollapse: true,
    authenticted: true,
  },
  {
    type: "collapse",
    name: "Buyers",
    key: "buyers",
    route: "/buyers",
    href: "https://accounts.cimpress.io/g2Ez5VaoZWoqU22XqPjTLU/fulfillment/buyers",
    icon: <Office size="12px" />,
    noCollapse: true,
    authenticted: true,
  },
  {
    type: "collapse",
    name: "Warehouse",
    key: "warehouse",
    route: "/warehouse",
    icon: <Shop size="12px" />,
    component: <Product />,
    noCollapse: true,
    authenticted: true,
  },
  {
    type: "title",
    title: "Premium Segment",
    key: "premium",
    authenticted: true,
  },
  {
    type: "collapse",
    name: "Memberships",
    key: "memberships",
    route: "/memberships",
    icon: <Document size="12px" />,
    component: <Memberships />,
    noCollapse: true,
    authenticted: true,
  },
  {
    type: "collapse",
    name: "Partner Program",
    key: "partner-program",
    route: "/partner-program",
    icon: <Shop size="12px" />,
    component: <Partner />,
    noCollapse: true,
    authenticted: true,
  },
  {
    type: "title",
    title: "Orders & Payments",
    key: "payments",
    authenticted: true,
  },
  {
    type: "collapse",
    name: "Orders",
    key: "orders",
    route: "/orders",
    icon: <Basket size="12px" />,
    component: <Order />,
    noCollapse: true,
    authenticted: true,
  },
  {
    type: "collapse",
    name: "Billing",
    key: "billing",
    route: "/billing",
    icon: <CreditCard size="12px" />,
    component: <Billing />,
    noCollapse: true,
    authenticted: true,
  },
  {
    type: "title",
    title: "Profile",
    key: "profiles",
    authenticted: true,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    route: "/profile",
    icon: <CustomerSupport size="12px" />,
    component: <Profile />,
    noCollapse: true,
    authenticted: true,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    route: "/authentication/sign-in",
    icon: <Document size="12px" />,
    component: <SignIn />,
    noCollapse: true,
    authenticted: false,
  },
  {
    type: "collapse",
    name: "Setting",
    key: "settings",
    route: "/settings",
    icon: <Settings size="12px" />,
    component: <Profile />,
    noCollapse: true,
    authenticted: true,
  },
  {
    type: "title",
    title: "Development",
    key: "development",
    authenticted: true,
  },
  {
    type: "collapse",
    name: "Documentation",
    key: "documentation",
    route: "/documentation",
    href: 'https://developer.cimpress.io/software-catalog',
    icon: <Settings size="12px" />,
    noCollapse: true,
    authenticted: true,
  },
  {
    type: "collapse",
    name: "Customer Support",
    key: "customersupport",
    route: "/support",
    icon: <CustomerSupport size="12px" />,
    component: <Profile />,
    noCollapse: true,
    authenticted: true,
  },
];

export default routes;
