import { Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

const styleBus = {
  "text-align": "center",
};

export default function Aboutus() {
  return (
    <SoftBox mb={4}>
      <Grid mb={4}>
        <SoftTypography
          mt={5}
          style={styleBus}
          variant="h2"
          fontWeight="bold"
          textTransform="capitalize"
        >
          About Us{" "}
        </SoftTypography>
      </Grid>
      <SoftTypography m={4} style={styleBus}>
        CimPL Technology empowers mass customization businesses through
        software, tools, and services that are “built for change” via API-first
        micro-services. The power of our technologies is the ease and speed
        businesses can leverage to serve the diverse and evolving needs of their
        customers -- far more efficiently than they could alone. We focus on
        delivering scalable products and solutions that unlock data-driven
        decision making, deliver jaw dropping customer value, and reduce the
        heavy lifting for the most common patterns and functions required by
        mass customization businesses.
      </SoftTypography>
    </SoftBox>
  );
}
