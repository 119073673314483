import CoverLayout from "./components/CoverLayout";
import Hero from "./components/Hero";
// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
// Material Kit 2 React themes
import curved from "assets/images/curved-images/curved-6.jpg";
import theme from "assets/theme";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import DefaultNavbar from "./components/Navbar";
import PageLayout from "./components/Page layout";
import Project from "./components/Projects";
import Footer from "./components/Footer";
import { Card } from "@mui/material";
import Benefit from "./components/Benefits";
import Review from "./components/Reviews";
import Business from "./components/businesses";
import Aboutus from "./components/AboutUs";

export default function LandingPage() {
  const loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'));
  return (
    <PageLayout background="grey-200">
      <DefaultNavbar loggedInUser={loggedInUser} />
      <Hero />
      <Business />
      <Project />
      <Benefit />
      <Review />
      <Aboutus/>
      <Footer />
    </PageLayout>
  );
}
