// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// cimPL Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import React from "react";

function MembershipCard({
  variant,
  color,
  icon,
  title,
  subtitle,
  description,
  value,
}) {
  return (
    <Card>
      <SoftBox p={2} mx={3} display="flex" justifyContent="center">
        <SoftBox
          display="grid"
          justifyContent="center"
          alignItems="center"
          bgColor={color}
          color="white"
          width="4rem"
          height="4rem"
          shadow="md"
          borderRadius="lg"
          variant="gradient"
        >
          <Icon fontSize="default">{icon}</Icon>
        </SoftBox>
      </SoftBox>
      <SoftBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
        <SoftTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
        >
          {title}
        </SoftTypography>
        <SoftTypography variant="h6" fontWeight="bold">
          {subtitle}
        </SoftTypography>
        {description.length &&
          description.map((item, index) => {
            return (
              <React.Fragment key={index+"_test"}>
                <SoftTypography
                  variant={variant}
                  color="text"
                  fontWeight="regular"
                >
                  {item}
                </SoftTypography>
                <br />
              </React.Fragment>
            );
          })}
        {description.length && !value ? null : <Divider />}
        {value && (
          <SoftButton variant="h5" fontWeight="medium">
            {value}
          </SoftButton>
        )}
      </SoftBox>
      {value == '$0.00' ? <SoftButton color="info">Current Plan</SoftButton> : <SoftButton color="dark">Try Now</SoftButton>}
    </Card>
  );
}

// Setting default values for the props of MembershipCard
MembershipCard.defaultProps = {
  color: "info",
  value: "",
  description: [""],
};

// Typechecking props for the MembershipCard
MembershipCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default MembershipCard;
