import { Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import shopify from "assets/images/shopifyg.svg";
import amazon from "assets/images/icons8-amazon-50.png";
import walmart from "assets/images/walmart.svg";
import etsy from "assets/images/etsy.svg";
import youtube from "assets/images/youtube.svg";
import weebly from "assets/images/weebly.svg";
import wix from "assets/images/wix.svg";
import ebay from "assets/images/ebay.svg";
import webflow from "assets/images/webflow.svg";
import Adobe from "assets/images/adobe_commerce.svg";
import instagram from "assets/images/instagram.svg";
import api from "assets/images/api.svg";
import manual from "assets/images/manual.svg";
import woo from "assets/images/woocommerce.svg";
// Soft UI Dashboard React examples
import Settings from "examples/Icons/Settings";
import DefaultInfoCard from "./DefaultInfoCard/index.js";
import { IntegrationInstructions } from "@mui/icons-material";

export default function Store() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox>
          <SoftTypography variant="h2" fontWeight="bold">
            Connect Your Store
          </SoftTypography>
        </SoftBox>
        <SoftBox>
          <SoftTypography variant="h5" fontWeight="medium">
            Choose your platform or marketplace to connect it. Check our
            step-by-step guide for more Information.
          </SoftTypography>
        </SoftBox>

        <SoftBox py={3}>
          <SoftTypography variant="h5" fontWeight="medium">
            Direct Integrations{" "}
            <IntegrationInstructions size="18px" color="error" />
          </SoftTypography>
          <SoftBox py={6}>
            <Grid container spacing={3} lg={20}>
              <Grid item md={4} xl={5}>
                <DefaultInfoCard
                  icon={shopify}
                  title="Shopify"
                  description="attractive choice for small businesses, offering a comprehensive platform with easy-to-use tools and affordable pricing. "
                  value="$455.00"
                  color="secondary"
                />
              </Grid>
              <Grid item xs={12} md={6} xl={5}>
                <DefaultInfoCard
                  icon={amazon}
                  title="Amazon"
                  description="global platform that provides online merchants with an opportunity to sell to a large customer base through Amazon's e-commerce platform. "
                  value="+$2000"
                  color="secondary"
                />
              </Grid>
              <Grid item xs={12} md={6} xl={5}>
                <DefaultInfoCard
                  icon={walmart}
                  title="wallmart"
                  description="Whether you’re a small business or global brand, their innovative solutions simplify the selling experience and help deliver growth at scale."
                  value="$455.00"
                  color="secondary"
                />
              </Grid>
              <Grid item xs={8} md={6} xl={5}>
                <DefaultInfoCard
                  icon={etsy}
                  title="Etsy"
                  description="simple and fast way to start selling and connect with millions of active buyers worldwide, making it an ideal choice for new merchants. "
                  value="$455.00"
                  color="secondary"
                />
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
        <SoftBox py={3}>
          <SoftTypography variant="h5" fontWeight="medium">
            Other Integrations <Settings size="18px" color="info" />
          </SoftTypography>
          <SoftBox py={4} mr={5}>
            <Grid container spacing={3} lg={20}>
              <Grid item md={4} xl={3}>
                <DefaultInfoCard
                  icon={Adobe}
                  title="Adobe"
                  description="lexible and scalable commerce platform that lets you create uniquely personalized B2B and B2C experiencess"
                  value="$455.00"
                  color="secondary"
                />
              </Grid>
              <Grid item xs={12} md={6} xl={3}>
                <DefaultInfoCard
                  icon={wix}
                  title="Wix"
                  description="popular website builder,user-friendly platform to create an online store that is visually stunning and easy to navigate."
                  value="+$2000"
                  color="secondary"
                />
              </Grid>
              <Grid item xs={12} md={6} xl={3}>
                <DefaultInfoCard
                  icon={webflow}
                  title="Webflow"
                  description="Push the limits of web design and create unique commerce experiences for your customers — all while building a business"
                  value="$455.00"
                  color="secondary"
                />
              </Grid>
              <Grid item xs={12} md={6} xl={3}>
                <DefaultInfoCard
                  icon={ebay}
                  title="eBay"
                  description="It is world's 2nd biggest marketplace, with 147 million active buyers and over 1 billion monthly visitors."
                  value="$455.00"
                  color="secondary"
                />
              </Grid>
              <Grid item xs={8} md={6} xl={3}>
                <DefaultInfoCard
                  icon={instagram}
                  title="Instagram"
                  description="creator marketplace helps creators connect with brands and partner on branded content and partnership ads."
                  value="$455.00"
                  color="secondary"
                />
              </Grid>
              <Grid item xs={12} md={6} xl={3}>
                <DefaultInfoCard
                  icon={woo}
                  title="Woocommerce"
                  description="powerful e-commerce platform designed for merchants who require extensive customization and scalability.
                  "
                  value="$455.00"
                  color="secondary"
                />
              </Grid>
              <Grid item xs={12} md={6} xl={3}>
                <DefaultInfoCard
                  icon={youtube}
                  title="Youtube"
                  description="YouTube Shopping allows eligible creators to showcase their products and official branded merchandise on YouTube.ss"
                  value="$455.00"
                  color="secondary"
                />
              </Grid>
              <Grid item xs={12} md={6} xl={3}>
                <DefaultInfoCard
                  icon={weebly}
                  title="Weebly"
                  description="user-friendly website builder that offers a comprehensive platform for creating and launching an online store."
                  value="$455.00"
                  color="secondary"
                />
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
        <SoftBox py={3}>
          <SoftTypography variant="h5" fontWeight="medium">
            Custom Integrations {"</>"}
          </SoftTypography>
        </SoftBox>
        <SoftBox py={6}>
          <Grid container spacing={3} lg={20}>
            <Grid item md={4} xl={5}>
              <DefaultInfoCard
                icon={manual}
                title="Manual"
                description="The custom store is a not an integrated store. Use it to test creating products, save them and add them to future orders"
                value="$455.00"
                color="secondary"
              />
            </Grid>
            <Grid item xs={12} md={6} xl={5}>
              <DefaultInfoCard
                icon={api}
                title="Api"
                description="The CimPL API uses REST, with resource-oriented URLs, HTTP response codes for errors, and JSON for responses. e"
                value="+$2000"
                color="secondary"
              />
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
    </DashboardLayout>
  );
}
