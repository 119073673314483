import React, { useEffect, useState } from 'react'
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";

// cimPL Dashboard React Context Provider
import { SoftUIControllerProvider } from "context";
import { allowedUsers } from 'constant';
import { Auth0Provider } from '@auth0/auth0-react';

const root = ReactDOM.createRoot(document.getElementById("root"));

const Root = () => {
  const [loading, setLoading] = useState(true)
  /** 
   * Below code is for testing the app and shouldn't be part of actual lock-widget implementation
   */
  useEffect(() => {
    window.allUsers = allowedUsers;
    setLoading(false)
  }, [])

  return (loading ? null :
    <Auth0Provider
      domain="cimpress-dev.auth0.com"
      clientId="38hN3moQPnBX1JxGGQwH7iHT3dTjNU5y"
      authorizationParams={{
        redirect_uri: 'https://cimple.platform.cimpress.io/dashboard'
      }}
    >
      <BrowserRouter>
        <SoftUIControllerProvider>
          <App />
        </SoftUIControllerProvider>
      </BrowserRouter>
    </Auth0Provider>)
}

root.render(<Root />);
