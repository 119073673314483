import React, { useCallback, useEffect, useState } from 'react'
import { isEmpty } from 'lodash'

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// cimPL Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
// Images
import curved9 from "assets/images/curved-images/curved-6.jpg";
import BasicLayout from "../components/BasicLayout";
import Socials from "layouts/authentication/components/Socials";
import Separator from "layouts/authentication/components/Separator";
// Images
import curved6 from "assets/images/curved-images/curved14.jpg";
import { Card, Checkbox } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";


function SignIn() {
  const navigate = useNavigate()
  const { loginWithRedirect, user, isAuthenticated, isLoading } = useAuth0();


  const  handleLogIn= async () => {
    await loginWithRedirect()
    // console.log("User....", user);
    // localStorage.setItem('loggedInUser', JSON.stringify(user));
  }

  useEffect(() => {
    if (isAuthenticated) {
      console.log("User...1.", user);
      localStorage.setItem('loggedInUser', JSON.stringify(user));
      if (user) {
        navigate('/dashboard')
      }
    }
  }, [isAuthenticated, user])

  return (
    <BasicLayout
      title="Welcome Back!"
      description="Sign in to manage your account"
      image={curved6}
    >
      <Card>
        <SoftBox p={3} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
            Login with
          </SoftTypography>
        </SoftBox>
        <SoftBox mb={2}>
          <Socials loginWithRedirect={handleLogIn}/>
        </SoftBox>
        <Separator />
        <SoftBox pt={2} pb={3} px={3}>
          <SoftBox component="form" role="form">
            <SoftBox mt={4} mb={1}>
              <SoftButton variant="gradient" color="dark" fullWidth onClick={loginWithRedirect}>
                Login Or SignUp
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </Card>
    </BasicLayout>
  );
}

export default SignIn;
