import { Card, Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import PlaceholderCard from "examples/Cards/PlaceholderCard";

import cimpressCart from "assets/images/cimpressCart.png";
import customCup from "assets/images/customize cup.png";
import customize from "assets/images/customize.jpg";
import customizeData from "assets/images/customizeData.png";

export default function Project() {
  return (
    <SoftBox mb={3}>
      <Card>
        <SoftBox pt={2} px={2}>
          <SoftBox mb={0.5}>
            <SoftTypography variant="h2" fontWeight="medium">
              Our Comprehensive Product Suite
            </SoftTypography>
          </SoftBox>
          {/* <SoftBox mb={1}>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              Architects design houses
            </SoftTypography>
          </SoftBox> */}
        </SoftBox>
        <SoftBox p={2}>
          <Grid container spacing={3}>
            <Grid item xs={10} md={5} xl={3}>
              <DefaultProjectCard
                image={cimpressCart}
                title="Millions of products"
                description="Quickly adapt to changing market conditions for faster new product introduction and more intelligent data-driven marketing.                "
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "Learn More",
                }}
              />
            </Grid>
            <Grid item xs={10} md={5} xl={3}>
              <DefaultProjectCard
                image={customCup}
                title="Simple Customisation"
                description="Quickly adapt to Adopt tools and services for reliable and cost-effective design generation, preview, automated file checking, image placement, reduction to single color, and the creation of press-ready artwork. market conditions for faster new product introduction and more intelligent data-driven marketing.                "
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "Learn More",
                }}
              />
            </Grid>
            <Grid item xs={10} md={5} xl={3}>
              <DefaultProjectCard
                image={customize}
                title="Global Fulfillment"
                description="Quickly adapt to changing Leverage solutions that efficiently automate shop floor processes, simplify manufacturing system integration, and that allow businesses to scale for increased customer demand across an expanding delivery and cost options network. conditions for faster new product introduction and more intelligent data-driven marketing.                "
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "Learn More",
                }}
              />
            </Grid>
            <Grid item xs={10} md={5} xl={3}>
              <DefaultProjectCard
                image={customizeData}
                title="Thousands of Integrations"
                description="Transform your business through a self-service data marketplace where producers and consumers can turn data into actionable insight. Build exactly what you want and perform the analysis you need using the business knowledge and experience you uniquely have.
                adapt to changing market conditions for faster new product introduction and more intelligent data-driven marketing.                "
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "Learn More",
                }}
              />
            </Grid>
          </Grid>
        </SoftBox>
      </Card>
    </SoftBox>
  );
}
