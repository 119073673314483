export const allowedUsers = [{
    id: 1,
    name: 'amar',
    email: 'amar.singh@cimpress.com',
    pwd: '1234@cimpl'
},
{
    id: 2,
    name: 'amar',
    email: 'amar',
    pwd: 'amar'
}]