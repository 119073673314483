/* eslint-disable react/prop-types */
// cimPL Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftBadge from "components/SoftBadge";
// import dummyData from "../";
// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

function Author({ image, name, email }) {
  return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox mr={2}>
        <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="button" fontWeight="medium">
          {name}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {email}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}

function Function({ job, org }) {
  return (
    <SoftBox display="flex" flexDirection="column">
      <SoftTypography variant="caption" fontWeight="medium" color="text">
        {job}
      </SoftTypography>
      <SoftTypography variant="caption" color="secondary">
        {org}
      </SoftTypography>
    </SoftBox>
  );
}

const authorsTableData = {
  columns: [
    { name: "Product", align: "left" },
    { name: "Price", align: "left" },
    { name: "quantity", align: "center" },
    { name: "status", align: "center" },
    { name: "action", align: "center" },
  ],

  rows: [
    {
      Product: (
        <Author
          image="https://fakestoreapi.com/img/71-3HjGNDUL._AC_SY879._SX._UX._SY._UY_.jpg"
          name="Mens Casual Premium Slim Fit T-Shirts"
          email="Slim-fitting style, contrast raglan long sleeve."
        />
      ),
      Price: <Function job="$22" />,
      status: (
        <SoftBadge
          variant="gradient"
          badgeContent="In-Stock"
          color="success"
          size="xs"
          container
        />
      ),
      quantity: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          2
        </SoftTypography>
      ),
      action: (
        <SoftTypography
          component="a"
          href="#"
          variant="caption"
          color="secondary"
          fontWeight="medium"
        >
          Edit
        </SoftTypography>
      ),
    },
    {
      Product: (
        <Author
          image="https://i.imgur.com/R3iobJA.jpeg"
          name="Classic Navy Blue Baseball Cap"
          email="Crafted from durable material, it features a smooth, structured design and an adjustable strap for the perfect fit."
        />
      ),
      Price: <Function job="$40" />,
      status: (
        <SoftBadge
          variant="gradient"
          badgeContent="Delivered"
          color="success"
          size="xs"
          container
        />
      ),
      quantity: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          4
        </SoftTypography>
      ),
      action: (
        <SoftTypography
          component="a"
          href="#"
          variant="caption"
          color="secondary"
          fontWeight="medium"
        >
          Edit
        </SoftTypography>
      ),
    },
    {
      Product: (
        <Author
          image="https://fakestoreapi.com/img/61sbMiUnoGL._AC_UL640_QL65_ML3_.jpg"
          name="Solid Gold Petite Micropave"
          email="Satisfaction Guaranteed. Return or exchange any order within 30 days."
        />
      ),
      Price: <Function job="$109" />,
      status: (
        <SoftBadge
          variant="gradient"
          badgeContent="In-Stock"
          color="success"
          size="xs"
          container
        />
      ),
      quantity: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          1
        </SoftTypography>
      ),
      action: (
        <SoftTypography
          component="a"
          href="#"
          variant="caption"
          color="secondary"
          fontWeight="medium"
        >
          Edit
        </SoftTypography>
      ),
    },
    {
      Product: (
        <Author
          image="https://fakestoreapi.com/img/71YAIFU48IL._AC_UL640_QL65_ML3_.jpg"
          name="White Gold Plated Princess"
          email="Classic Created Wedding Engagement Solitaire Diamond Promise Ring."
        />
      ),
      Price: <Function job="22" />,
      status: (
        <SoftBadge
          variant="gradient"
          badgeContent="In-Stock"
          color="success"
          size="xs"
          container
        />
      ),
      quantity: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          1
        </SoftTypography>
      ),
      action: (
        <SoftTypography
          component="a"
          href="#"
          variant="caption"
          color="secondary"
          fontWeight="medium"
        >
          Edit
        </SoftTypography>
      ),
    },
    {
      Product: (
        <Author
          image="https://fakestoreapi.com/img/71HblAHs5xL._AC_UY879_-2.jpg"
          name="Rain Jacket Women"
          email="Lightweight perfet for trip or casual wear."
        />
      ),
      Price: <Function job="$30" />,
      status: (
        <SoftBadge
          variant="gradient"
          badgeContent="Out Of Stock"
          color="error"
          size="xs"
          container
        />
      ),
      quantity: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          7
        </SoftTypography>
      ),
      action: (
        <SoftTypography
          component="a"
          href="#"
          variant="caption"
          color="secondary"
          fontWeight="medium"
        >
          Edit
        </SoftTypography>
      ),
    },
    {
      Product: (
        <Author
          image="https://fakestoreapi.com/img/51eg55uWmdL._AC_UX679_.jpg"
          name="Women's Short Sleeve"
          email="100% Polyester, Machine wash."
        />
      ),
      Price: <Function job="$50" />,
      status: (
        <SoftBadge
          variant="gradient"
          badgeContent="In-Stock"
          color="success"
          size="xs"
          container
        />
      ),
      quantity: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          4
        </SoftTypography>
      ),
      action: (
        <SoftTypography
          component="a"
          href="#"
          variant="caption"
          color="secondary"
          fontWeight="medium"
        >
          Edit
        </SoftTypography>
      ),
    },
    {
      Product: (
        <Author
          image="https://fakestoreapi.com/img/61pHAEJ4NML._AC_UX679_.jpg"
          name="Womens T Shirt Casual Cotton Short"
          email="95%Cotton,5%Spandex, Features: Casual, Short Sleeve."
        />
      ),
      Price: <Function job="$12" />,
      status: (
        <SoftBadge
          variant="gradient"
          badgeContent="In-Stock"
          color="success"
          size="xs"
          container
        />
      ),
      quantity: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          5
        </SoftTypography>
      ),
      action: (
        <SoftTypography
          component="a"
          href="#"
          variant="caption"
          color="secondary"
          fontWeight="medium"
        >
          Edit
        </SoftTypography>
      ),
    },
    {
      Product: (
        <Author
          image="https://fakestoreapi.com/img/71z3kpMAYsL._AC_UY879_.jpg"
          name="Women's Solid Short Sleeve Boat Neck V"
          email="95% RAYON 5% SPANDEX, Made in USA or Imported."
        />
      ),
      Price: <Function job="$5" />,
      status: (
        <SoftBadge
          variant="gradient"
          badgeContent="Out Of Stock"
          color="error"
          size="xs"
          container
        />
      ),
      quantity: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          10
        </SoftTypography>
      ),
      action: (
        <SoftTypography
          component="a"
          href="#"
          variant="caption"
          color="secondary"
          fontWeight="medium"
        >
          Edit
        </SoftTypography>
      ),
    },
    {
      Product: (
        <Author
          image="https://fakestoreapi.com/img/81XH0e8fefL._AC_UY879_.jpg"
          name="Women's Removable Hooded Faux Leather Moto Biker Jacket"
          email="100% POLYURETHANE(shell) 100% POLYESTER(lining) 75% POLYESTER."
        />
      ),
      Price: <Function job="$10" />,
      status: (
        <SoftBadge
          variant="gradient"
          badgeContent="In-Stock"
          color="success"
          size="xs"
          container
        />
      ),
      quantity: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          4
        </SoftTypography>
      ),
      action: (
        <SoftTypography
          component="a"
          href="#"
          variant="caption"
          color="secondary"
          fontWeight="medium"
        >
          Edit
        </SoftTypography>
      ),
    },
    {
      Product: (
        <Author
          image="https://fakestoreapi.com/img/71pWzhdJNwL._AC_UL640_QL65_ML3_.jpg"
          name="Chain Bracelet"
          email="Wear facing inward to be bestowed with love and abundance."
        />
      ),
      Price: <Function job="$70" />,
      status: (
        <SoftBadge
          variant="gradient"
          badgeContent="In-Stock"
          color="success"
          size="xs"
          container
        />
      ),
      quantity: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          2
        </SoftTypography>
      ),
      action: (
        <SoftTypography
          component="a"
          href="#"
          variant="caption"
          color="secondary"
          fontWeight="medium"
        >
          Edit
        </SoftTypography>
      ),
    },
    {
      Product: (
        <Author
          image="https://i.imgur.com/1twoaDy.jpeg"
          name="Classic Red Pullover Hoodie"
          email="Crafted with a soft cotton blend for ultimate comfort, this vibrant red hoodie."
        />
      ),
      Price: <Function job="@25" />,
      status: (
        <SoftBadge
          variant="gradient"
          badgeContent="In-Stock"
          color="success"
          size="xs"
          container
        />
      ),
      quantity: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          5
        </SoftTypography>
      ),
      action: (
        <SoftTypography
          component="a"
          href="#"
          variant="caption"
          color="secondary"
          fontWeight="medium"
        >
          Edit
        </SoftTypography>
      ),
    },
    {
      Product: (
        <Author
          image="https://i.imgur.com/ZKGofuB.jpeg"
          name="Classic Comfort Fit Joggers"
          email="versatile black joggers feature a soft elastic waistband with an adjustable drawstring."
        />
      ),
      Price: <Function job="$30" />,
      status: (
        <SoftBadge
          variant="gradient"
          badgeContent="In-Stock"
          color="success"
          size="xs"
          container
        />
      ),
      quantity: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          5
        </SoftTypography>
      ),
      action: (
        <SoftTypography
          component="a"
          href="#"
          variant="caption"
          color="secondary"
          fontWeight="medium"
        >
          Edit
        </SoftTypography>
      ),
    },

    {
      Product: (
        <Author
          image="https://fakestoreapi.com/img/61sbMiUnoGL._AC_UL640_QL65_ML3_.jpg"
          name="Solid Gold Petite Micropave"
          email="Satisfaction Guaranteed. Return or exchange any order within 30 days."
        />
      ),
      Price: <Function job="$109" />,
      status: (
        <SoftBadge
          variant="gradient"
          badgeContent="In-Stock"
          color="success"
          size="xs"
          container
        />
      ),
      quantity: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          1
        </SoftTypography>
      ),
      action: (
        <SoftTypography
          component="a"
          href="#"
          variant="caption"
          color="secondary"
          fontWeight="medium"
        >
          Edit
        </SoftTypography>
      ),
    },
  ],
};

export default authorsTableData;
