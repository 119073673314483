import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";

// Material Kit 2 React components
// Images
import bgImage from "assets/images/bg.jpg";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import SoftButton from "components/SoftButton";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Card } from "@mui/material";
import SimpleBlogCard from "examples/Cards/BlogCards/DefaultBlogCard";
import customizePlatform from "assets/images/hero pic.jpg";
import { useNavigate } from "react-router-dom";

const flexStyle = {
  display: "inline-flex",
  justifyContent: "center",
  // margin: "auto",
};

function Hero() {
  const navigate = useNavigate()

  const startSellingHandler = () => {
    navigate('/authentication/sign-in')
  }
  
  return (
    <SoftBox component="header" position="relative" height="100%">
      <SoftBox
        display="flex"
        alignItems="center"
        minHeight="100vh"
        sx={{
          backgroundImage: ({
            palette: { gradients },
            functions: { linearGradient, rgba },
          }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.5),
              rgba(gradients.dark.state, 0.5)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Container style={flexStyle}>
          <Grid
            container
            item
            xs={12}
            md={7}
            lg={6}
            flexDirection="column"
            justifyContent="center"
          >
            <SoftTypography
              variant="h1"
              color="white"
              mb={3}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Build and grow your
              <br />
              e-Commerce business with CimPL
            </SoftTypography>
            <SoftTypography
              variant="body1"
              color="white"
              opacity={0.8}
              pr={6}
              mr={6}
            >
              Create and sell custom on-demand products with no inventory
            </SoftTypography>
            <Stack direction="row" spacing={1} mt={3}>
              <SoftButton color="white" onClick={startSellingHandler} >Start Selling</SoftButton>
              <SoftButton variant="text" color="white">
                Learn more
              </SoftButton>
            </Stack>
          </Grid>
          <Grid container item xs={4} mt={5} ml={5}>
            <SimpleBlogCard
              image={customizePlatform}
              title="One Platform — Many Solutions"
              description="A Simple and Unified Print on Demand SaaS Platform that empowers e-commerce businesses source and sell customised goods across the globe in the most Simple, Secure, and Sustainable way. "
              action={{
                type: "internal",
                route: "/home",
                color: "info",
                label: "Go Somewhere",
              }}
            />
          </Grid>
        </Container>
      </SoftBox>
    </SoftBox>
  );
}

export default Hero;
