

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// cimPL Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// cimPL Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";

// cimPL Dashboard React base styles
import typography from "assets/theme/base/typography";

// Dashboard layout components
import BuildByDevelopers from "layouts/dashboard/components/BuildByDevelopers";
import WorkWithTheRockets from "layouts/dashboard/components/WorkWithTheRockets";
import Projects from "layouts/dashboard/components/Projects";
import OrderOverview from "layouts/dashboard/components/OrderOverview";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import gradientLineChartData from "layouts/dashboard/data/gradientLineChartData";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import tShirt from "assets/images/t-shirt.png";
import store from "assets/images/store-new.png";
import upload from "assets/images/illustrations/rocket-white.png";
import billing from "assets/images/billing.png";
import offer1Image from 'assets/images/fiftypercentoff.png'
import offer3Image from 'assets/images/thirtypercentoff.png'
import { useAuth0 } from "@auth0/auth0-react";

const stepsData = [{
  title: 'Design Your Products',
  step: 1,
  description: `You'll simply add any design to one of our hundreds of premium-quality products.`,
  time: '~25 Minutes',
  img: tShirt
},
{
  title: 'Connect Your Store',
  step: 2,
  description: `You'll easily connect your ecommerce platform to Printful in only a few clicks.`,
  time: '~10 Minutes',
  img: store
},
{
  title: 'Upload Products to Store',
  step: 3,
  description: `You'll give your product some context with a title, description, and price.`,
  time: '~15 Minutes',
  img: upload
},
{
  title: 'Set Up Billing',
  step: 4,
  description: `Input your billing info to cover the fulfilment fee each time you sell a product.`,
  time: '~5 Minutes',
  img: billing
}
]

const offerData = [{
  id: '1',
  title: 'Claim Your 1st Order Discount & Save huge!',
  description: 'See what your designs look like in real life. Order up to 3 products with your special welcome discount of 50%.',
  img: offer1Image
},
{
  id: '2',
  title: 'Your 2nd Order Discount to Save even more!',
  description: 'See what your designs look like in real life. Order up to 5 products with your special welcome discount of 30%.',
  img: offer3Image
}]

function Dashboard() {
  const navigate = useNavigate()
  const { size } = typography;
  const { chart, items } = reportsBarChartData;

  const { user, isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      const localUser = localStorage.getItem('loggedInUser');
      if (!localUser) {
        localStorage.setItem('loggedInUser', JSON.stringify(user));
      }
    } else if (!isLoading) {
      localStorage.removeItem('loggedInUser');
      navigate('/authentication/sign-in')
    }
  }, [isLoading])


  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            {stepsData.map(stepItem => {
              return <Grid item xs={12} lg={4} key={stepItem.step}>
                <BuildByDevelopers data={stepItem} />
              </Grid>
            })}
            {/*            
            <Grid item xs={12} lg={6}>
              <WorkWithTheRockets />
            </Grid> */}
          </Grid>
        </SoftBox>

        <SoftBox mb={3}>
          <Grid container spacing={2}>
            {offerData.map(offer => {
              return <Grid item xs={12} lg={5} key={offer.id}>
                <WorkWithTheRockets offerData={offer} />
              </Grid>
            })}
          </Grid>
        </SoftBox>

        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "today's money" }}
                count="$53,000"
                percentage={{ color: "success", text: "+55%" }}
                icon={{ color: "info", component: "paid" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "today's users" }}
                count="2,300"
                percentage={{ color: "success", text: "+3%" }}
                icon={{ color: "info", component: "public" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "new clients" }}
                count="+3,462"
                percentage={{ color: "error", text: "-2%" }}
                icon={{ color: "info", component: "emoji_events" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "sales" }}
                count="$103,430"
                percentage={{ color: "success", text: "+5%" }}
                icon={{
                  color: "info",
                  component: "shopping_cart",
                }}
              />
            </Grid>
          </Grid>
        </SoftBox>

        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={5}>
              <ReportsBarChart
                title="active users"
                description={
                  <>
                    (<strong>+23%</strong>) than last week
                  </>
                }
                chart={chart}
                items={items}
              />
            </Grid>
            <Grid item xs={12} lg={7}>
              <GradientLineChart
                title="Sales Overview"
                description={
                  <SoftBox display="flex" alignItems="center">
                    <SoftBox fontSize={size.lg} color="success" mb={0.3} mr={0.5} lineHeight={0}>
                      <Icon className="font-bold">arrow_upward</Icon>
                    </SoftBox>
                    <SoftTypography variant="button" color="text" fontWeight="medium">
                      4% more{" "}
                      <SoftTypography variant="button" color="text" fontWeight="regular">
                        in 2021
                      </SoftTypography>
                    </SoftTypography>
                  </SoftBox>
                }
                height="20.25rem"
                chart={gradientLineChartData}
              />
            </Grid>
          </Grid>
        </SoftBox>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8}>
            <Projects />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OrderOverview />
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
