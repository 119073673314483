import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import bgImage from "assets/images/curved-images/curved14.jpg";
import { Card, CardMedia, Container, Grid, Stack } from "@mui/material";
import SoftButton from "components/SoftButton";
import customizePlatform from "assets/images/pexels-photo-handshake.webp";
import MembershipCard from "./MembershipInfoCard";
const flexStyle = {
  display: "inline-flex",
  justifyContent: "center",
  // margin: "auto",
};

const cardImageStyles = {
  width: "100%",
  height: "auto",
  minHeight: ({ functions: { pxToRem } }) => pxToRem(235),
  margin: 0,
  boxShadow: ({ boxShadows: { md } }) => md,
  transformOrigin: "50% 0",
  transform: `perspective(999px) rotateX(0deg) translateZ(0)`,
  WebkitBackfaceVisibility: "hidden",
  backfaceVisibility: "hidden",
  willChange: "transform, box-shadow",
  transition: ({ transitions }) =>
    transitions.create("transform", {
      easing: transitions.easing.easeIn,
      duration: transitions.duration.shorter,
    }),

  "&:hover": {
    transform: `perspective(999px) rotateX(7deg) translate3d(0, -4px, 5px)`,
  },
};
const partnerData = [
  {
    icon: "spaceship",
    title: " Referral Program",
    subtitle: "",
    description: [
      "Are you excited to use CimPl and want to share this experience with others? Choose CimPL's Referral Program to refer friends, family, colleagues, or other people you may know and earn referral points!",

      "What you get:",
      "1. $5 discount for your friends on their first order",
      "2. $5 in Printful points for you to spend on perks",
      "3. A chance to earn up to $1000 in Printful points",

      //
    ],
    value: "Become a Partner",
  },
  {
    icon: "payment",
    title: " Affiliate Program ",
    subtitle: "Everything in cimPL+, and:",
    description: [
      "        Have an audience that would benefit from using CimPl? If you like to create content,motivate people to start their online business with a print-on-demand company",
      "        What you get:",
      "1. 10% commission from every order",
      "2. Recurring commission for 12 months",
      " 3. Unlimited referrals and earnings",
    ],
    value: "Become a Partner",
  },
  {
    icon: "code",
    title: "Integration Partnership",
    // subtitle: "Everything in Gold, and",
    description: [
      "Partner wth CimPl's Platform to let the businesses Integrate with your sevices ",
      "        What you get:",
      "1. 15% commission from every order",
      "2. Cost Savings as Mass Orders",
      "3. Expert Hours with CimPL's solutions consulting team",
      "4. Recurring commission for 12 months",
    ],
    value: " Become a Partner",
  },
];

export default function Partner() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py="3">
        <SoftBox
          display="flex"
          alignItems="center"
          minHeight="40vh"
          sx={{
            backgroundImage: ({
              palette: { gradients },
              functions: { linearGradient, rgba },
            }) =>
              `${linearGradient(
                rgba(gradients.secondary.main, 0.5),
                rgba(gradients.dark.state, 0.5)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Container style={flexStyle}>
            <Grid
              container
              item
              xs={12}
              md={7}
              lg={6}
              flexDirection="column"
              justifyContent="center"
            >
              <SoftTypography
                variant="h1"
                color="white"
                mb={3}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Become a cimPL Partner
              </SoftTypography>
              <SoftTypography
                variant="subtitle1"
                color="white"
                fontWeight="medium"
                opacity={1}
                pr={6}
                mr={6}
              >
                Our Partner programs are for bloggers, agencies, creators,
                entrepreneurs, and anyone with an interest in working with us.
                Join our Affiliate Program or Referral Program. Not sure which
                one is the right fit for you?
              </SoftTypography>
              <Stack direction="row" spacing={1} mt={3}>
                <SoftButton color="white">Join Now</SoftButton>
                <SoftButton variant="text" color="white">
                  Learn more
                </SoftButton>
              </Stack>
            </Grid>
            <Grid container item xs={4} mt={5} ml={5}>
              <Card
                sx={{
                  background: "transparent",
                  boxShadow: "none",
                  overflow: "visible",
                }}
              >
                <CardMedia
                  src={customizePlatform}
                  component="img"
                  //   title={title}
                  sx={cardImageStyles}
                />
              </Card>
            </Grid>
          </Container>
        </SoftBox>
      </SoftBox>
      <SoftBox py={3}>
        <SoftTypography variant="h2" fontWeight="medium">
          Find the program most suitable to your needs
        </SoftTypography>
        <SoftBox py={4} m="auto">
          <Grid container spacing={3} lg={12}>
            {partnerData.map((mem) => {
              return (
                <Grid item xs={12} md={6} xl={4}>
                  <MembershipCard
                    variant="subtitle2"
                    icon={mem.icon}
                    title={mem.title}
                    description={mem.description}
                    value={mem.value}
                    subtitle={mem.subtitle}
                  />
                </Grid>
              );
            })}
          </Grid>
        </SoftBox>
      </SoftBox>
    </DashboardLayout>
  );
}
