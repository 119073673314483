import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";

import Country from "../dropdownCountry";
import BasicDatePicker from "../datePicker";
import { Select } from "@mui/material";
import SoftButton from "components/SoftButton";

export default function Filter() {
  return (
    <SoftBox>
      <SoftBox m={4}>
        <form>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="inputEmail4">Search</label>
              <SoftInput placeholder="Search for For Order Id, Name of Customer" />
            </div>
            <div class="form-group col-md-6">
              <label for="inputPassword4">Status</label>
              <SoftInput
                class="form-control"
                id="inputPassword4"
                placeholder="Onhold,Connected, Pending Approval"
              />
            </div>
          </div>
          <div class="form-row">
            <label
              for="inputPassword4"
              style={{ "margin-left": "0.5em", "margin-right": "1em" }}
            >
              Destination
            </label>
            <Country />
            <label
              for="inputAddress2"
              style={{ "margin-left": "2em", "margin-right": "1em" }}
            >
              Start Date
            </label>
            <BasicDatePicker />
            <label
              for="inputAddress2"
              style={{ "margin-left": "1em", "margin-right": "1em" }}
            >
              End Date
            </label>
            <BasicDatePicker />
          </div>
          <div class="form-row">
            <div class="form-group col-md-4">
              <label for="inputState">Fulfilment</label>
              <select id="inputState" class="form-control">
                <option>Printed</option>
                <option>Shipped</option>
                <option>In Transit</option>
                <option>Received</option>
              </select>
            </div>
            <div class="form-group col-md-4">
              <label for="inputState">Payment</label>
              <select id="inputState" class="form-control">
                <option>Received</option>
                <option>Failed</option>
                <option>Pending</option>
              </select>
            </div>
            <div class="form-group col-md-2">
              <label for="inputZip">Zip</label>
              <Select option="helo" />
            </div>
          </div>

          <button
            type="submit"
            class="btn btn-primary"
            style={{ "margin-right": "1em" }}
          >
            Search
          </button>
          <SoftButton size="small" variant="outlined" color="error">
            Reset Filters
          </SoftButton>
        </form>
        {/* </div> */}
      </SoftBox>
      {/* </div> */}
    </SoftBox>
  );
}
