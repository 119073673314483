import { Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MembershipCard from "examples/Cards/InfoCards/MembershipInfoCard";

const membershipData = [
  {
    icon: "payment",
    title: "Free",
    subtitle: "Free Plan includes",
    description: [
      "Production in 32 countries",
      "Sell on Shopify, Etsy and WooCommere",
      "Integrate with API and Order Desk",
      "Intuitive design editor",
    ],
    value: "+$0.00",
  },
  {
    icon: "payment",
    title: "cimPL+",
    subtitle: "Everything in Free, and:",
    description: [
      "Up to 10% off products.",
      "Millions of stock images graphics and fonts",
      "Branded labels and packaging inserts",
      "Product expansion tools",
    ],
    value: "+$50/month",
  },
  {
    icon: "payment",
    title: "Gold",
    subtitle: "Everything in cimPL+, and:",
    description: [
      "Up to 15% off products.",
      "30% off branded labels and packaging inserts",
      "Smart pricing for shipping",
      "Dedicated Customer Success Manager",
    ],
    value: "+$75/month",
  },
  {
    icon: "payment",
    title: "Platinum",
    subtitle: "Everything in Gold, and",
    description: [
      "Fixed rate shipping on all orders where cimPL produces locally",
      "Dedicated implementation Services",
      "Expert Hours with cimPL's solutions consulting team",
    ],
    value: "+$100/month",
  },
];

function Memberships() {
  return (
    <DashboardLayout>
      <SoftBox py={3}>
        <SoftBox>
          <SoftTypography variant="h2" fontWeight="bold">
            Membership Options
          </SoftTypography>
        </SoftBox>

        <SoftBox py={3}>
          <SoftTypography variant="h2" fontWeight="medium">
            Find the plan to build your business
          </SoftTypography>
          <SoftBox py={6}>
            <Grid container spacing={3} lg={12}>
              {membershipData.map((mem) => {
                return (
                  <Grid item xs={12} md={6} xl={3} key={mem.title}>
                    <MembershipCard
                      variant="caption"
                      icon={mem.icon}
                      title={mem.title}
                      description={mem.description}
                      value={mem.value}
                      subtitle={mem.subtitle}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </SoftBox>
          <SoftTypography variant="h6" fontWeight="medium">
            *cimPL+ subscribers receive up to 10% off eligible products. cimPL+
            Gold subscribers receive up to 15% off eligible products. A full
            list of discounts per product is available here. Terms & Conditions
            apply
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </DashboardLayout>
  );
}

export default Memberships;
