import { Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import buildasign from "assets/images/BAS_NEW_LOGO.svg";
import vista from "assets/images/Vista_Color.svg";
import wmd from "assets/images/WirMachenDruck.svg";
import printi from "assets/images/Printi_logo-oficial-rgb.svg";
import pixart from "assets/images/Pixartprinting.svg";
import druck from "assets/images/DruckAt.svg";
import druckprint from "assets/images/DWD-logo-FC.png";
import nationalpen from "assets/images/Pens_Pens-Logo-Dark_NOTAG52.svg";

const styleBus = {
  "text-align": "center",
};

export default function Business() {
  return (
    <SoftBox mb={4}>
      <Grid mb={4}>
        <SoftTypography
          mt={5}
          style={styleBus}
          variant="h2"
          fontWeight="bold"
          textTransform="capitalize"
        >
          Trusted by global brands
        </SoftTypography>
      </Grid>
      <Grid style={styleBus}>
        <SoftBox
          mr={2}
          mb={3}
          component="img"
          src={buildasign}
          width="10%"
        ></SoftBox>
        <SoftBox
          mr={2}
          mb={3}
          component="img"
          src={vista}
          width="10%"
        ></SoftBox>
        <SoftBox mr={2} mb={3} component="img" src={wmd} width="10%"></SoftBox>
        <SoftBox
          mr={2}
          mb={3}
          component="img"
          src={nationalpen}
          width="10%"
        ></SoftBox>
        <SoftBox
          mr={2}
          mb={3}
          component="img"
          src={druckprint}
          width="10%"
        ></SoftBox>
        <SoftBox
          mr={2}
          mb={3}
          component="img"
          src={druck}
          width="10%"
        ></SoftBox>
        <SoftBox
          mr={2}
          mb={3}
          component="img"
          src={pixart}
          width="10%"
        ></SoftBox>
        <SoftBox
          mr={2}
          mb={3}
          component="img"
          src={printi}
          width="10%"
        ></SoftBox>
        {/* <SoftBox
          mr={2}
          mb={3}
          component="img"
          src={buildasign}
          width="10%"
        ></SoftBox> */}
      </Grid>
    </SoftBox>
  );
}
