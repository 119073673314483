import { Card, Container, Grid, Icon } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import watermark from "assets/images/watermark-yaaubid5vc-transformed.jpeg";
import cimpIntegration from "assets/images/cimplIntegration(2).png";

const styleBus = {
  "text-align": "center",
};
const flexStyle = {
  display: "flex",
  "justify-content": "center",
};

export default function Benefit() {
  return (
    // <SoftBox mt={5} mb={3}>
    //   <Grid container spacing={3}>
    //     <Grid item xs={12} md={6} xl={4}>
    //       <Card>
    //         <SoftBox
    //           display="flex"
    //           flexDirection="column"
    //           height="100%"
    //           py={8}
    //           px={2}
    //           borderRadius="lg"
    //           sx={{
    //             backgroundImage: ({
    //               functions: { linearGradient, rgba },
    //               palette: { gradients },
    //             }) =>
    //               `${linearGradient(
    //                 rgba(gradients.secondary.main, 0.5),
    //                 rgba(gradients.secondary.state, 0.8)
    //               )}, url()`,
    //             backgroundSize: "cover",
    //           }}
    //         >
    //           <SoftBox pt={2} px={2}>
    //             <SoftTypography
    //               variant="h6"
    //               fontWeight="medium"
    //               textTransform="capitalize"
    //             >
    //               Heading 1
    //             </SoftTypography>
    //           </SoftBox>
    //           <SoftBox pt={5} px={10}>
    //             <SoftTypography variant="h5" fontWeight="bold" color="">
    //               Instantly access the broadest catalog of customizable products
    //               and a global network of manufacturers ready to fulfill your
    //               orders your way.
    //             </SoftTypography>
    //           </SoftBox>
    //         </SoftBox>
    //       </Card>
    //     </Grid>
    //     <Grid item xs={12} md={6} xl={4}>
    //       <Card>
    //         <SoftBox
    //           display="flex"
    //           flexDirection="column"
    //           height="100%"
    //           py={8}
    //           px={2}
    //           borderRadius="lg"
    //           sx={{
    //             backgroundImage: ({
    //               functions: { linearGradient, rgba },
    //               palette: { gradients },
    //             }) =>
    //               `${linearGradient(
    //                 rgba(gradients.secondary.main, 0.5),
    //                 rgba(gradients.secondary.state, 0.8)
    //               )}, url()`,
    //             backgroundSize: "cover",
    //           }}
    //         >
    //           <SoftBox pt={2} px={2}>
    //             <SoftTypography
    //               variant="h6"
    //               fontWeight="medium"
    //               textTransform="capitalize"
    //             >
    //               Heading 2
    //             </SoftTypography>
    //           </SoftBox>
    //           <SoftBox pt={5} px={10}>
    //             <SoftTypography variant="h5" fontWeight="bold" color="">
    //               Maximize production efficiencies and deliver high customer
    //               satisfaction, all while keeping both production and
    //               end-customer costs low.
    //             </SoftTypography>
    //           </SoftBox>
    //         </SoftBox>
    //       </Card>
    //     </Grid>
    //     <Grid item xs={12} md={6} xl={4}>
    //       <Card>
    //         <SoftBox
    //           display="flex"
    //           flexDirection="column"
    //           height="100%"
    //           py={8}
    //           px={2}
    //           borderRadius="lg"
    //           sx={{
    //             backgroundImage: ({
    //               functions: { linearGradient, rgba },
    //               palette: { gradients },
    //             }) =>
    //               `${linearGradient(
    //                 rgba(gradients.secondary.main, 0.5),
    //                 rgba(gradients.secondary.state, 0.8)
    //               )}, url()`,
    //             backgroundSize: "cover",
    //           }}
    //         >
    //           <SoftBox pt={2} px={2}>
    //             <SoftTypography
    //               variant="h6"
    //               fontWeight="medium"
    //               textTransform="capitalize"
    //             >
    //               Heading 3
    //             </SoftTypography>
    //           </SoftBox>
    //           <SoftBox pt={5} px={9}>
    //             <SoftTypography variant="h5" fontWeight="bold" color="">
    //               Leverage expansive modular, multi-tenant microservices, and
    //               customizable advanced technology solutions to improve quality,
    //               reliability, and delivery speed.
    //             </SoftTypography>
    //           </SoftBox>
    //         </SoftBox>
    //       </Card>
    //     </Grid>
    //   </Grid>
    // </SoftBox>
    <Card>
      <SoftBox p={2}>
        <Grid ml={1} item xs={12} lg={6} mb={5}>
          <SoftBox pt={1} mb={0.5} style={flexStyle}>
            <SoftTypography variant="h2" color="text" fontWeight="medium">
              Ecommerce platform integrations{" "}
            </SoftTypography>
          </SoftBox>
        </Grid>
        <Grid container spacing={3}>
          <Grid ml={25} item xs={12} lg={4} mr={5}>
            <SoftBox display="flex" flexDirection="column" height="100%">
              <SoftTypography variant="h3" fontWeight="bold" gutterBottom>
                Easy store connection
              </SoftTypography>
              <SoftBox mb={6}>
                <SoftTypography variant="body1" color="text">
                  Designed for creators and entrepreneurs, scaled for
                  enterprises. Thousands of businesses use CimPL's software and
                  APIs to produce and deliver custom printed products anywhere
                  in the world. Connect your store to CimPL’s API directly or
                  via our easy-to-use integrations with the leading ecommerce
                  platforms.
                </SoftTypography>
              </SoftBox>
              <SoftTypography
                component="a"
                href="#"
                variant="button"
                color="text"
                fontWeight="medium"
                sx={{
                  mt: "auto",
                  mr: "auto",
                  display: "inline-flex",
                  alignItems: "center",
                  cursor: "pointer",

                  "& .material-icons-round": {
                    fontSize: "1.125rem",
                    transform: `translate(2px, -0.5px)`,
                    transition:
                      "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
                  },

                  "&:hover .material-icons-round, &:focus  .material-icons-round":
                    {
                      transform: `translate(6px, -0.5px)`,
                    },
                }}
              >
                Read More
                <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
              </SoftTypography>
            </SoftBox>
          </Grid>
          <Grid
            mb={5}
            mr={8}
            item
            xs={12}
            lg={5}
            sx={{ position: "relative", ml: "auto" }}
          >
            <SoftBox
              height="100%"
              display="grid"
              justifyContent="center"
              alignItems="center"
              bgColor="dark"
              borderRadius="lg"
              variant="gradient"
            >
              {/* <SoftBox
                component="img"
                // src={wavesWhite}
                alt="waves"
                display="block"
                position="absolute"
                left={0}
                width="100%"
                height="100%"
              /> */}
              <SoftBox
                component="img"
                src={cimpIntegration}
                alt="rocket"
                width="100%"
                pt={3}
              />
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
}
