import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import cimpIntegration from "assets/images/cimplIntegration(2).png";
import DefaultBlogCard from "examples/Cards/BlogCards/DefaultBlogCard";
import SoftBox from "components/SoftBox";
import { Card, Grid } from "@mui/material";
import SoftTypography from "components/SoftTypography";
import image from "assets/images/team-1.jpg";
import SoftAvatar from "components/SoftAvatar";
import SoftButton from "components/SoftButton";
import image2 from "assets/images/team-2.jpg";
import image3 from "assets/images/team-3.jpg";
const borderStyle = {
  border: "10px",
  "border-color": "corola",
};
const flexStyle = {
  display: "flex",
  "justify-content": "center",
};
const textStyle = {
  display: "flex",
  "justify-content": "right",
  color: "#FA6607",
};

export default function Review() {
  return (
    <Card style={borderStyle}>
      <SoftBox p={2} mt={5}>
        <Grid ml={1} item xs={12} lg={6} mb={5}>
          <SoftBox pt={1} mb={0.5} style={flexStyle}>
            <SoftTypography variant="h2" color="text" fontWeight="medium">
              We have over 4,400 5-star reviews on all our businesses{" "}
            </SoftTypography>
          </SoftBox>
        </Grid>
        <SoftBox mt={5} mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} xl={4}>
              <Card>
                <SoftBox
                  component="li"
                  display="flex"
                  alignItems="center"
                  py={1}
                  mb={1}
                >
                  <SoftBox mr={2}>
                    <SoftAvatar
                      src={image2}
                      alt="something here"
                      variant="rounded"
                      shadow="md"
                    />
                  </SoftBox>
                  <SoftBox
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="center"
                  >
                    <SoftTypography variant="button" fontWeight="medium">
                      John Doe
                    </SoftTypography>
                    <SoftTypography variant="caption" color="text">
                      ClickCart Connect
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>
                <SoftBox pt={2} px={2}>
                  <SoftTypography variant="h5" mb={3}>
                    "When masks took off in the spring, address validation
                    helped us get hundreds of packages stuck on the shopfloor to
                    anxious customers. The ability to validate complete &
                    shippable addresses before an item even goes to production
                    made a huge difference."
                  </SoftTypography>
                  <SoftTypography variant="h4" style={textStyle}>
                    - Pixart Printing
                  </SoftTypography>
                </SoftBox>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <Card>
                <SoftBox
                  component="li"
                  display="flex"
                  alignItems="center"
                  py={1}
                  mb={1}
                >
                  <SoftBox mr={2}>
                    <SoftAvatar
                      src={image3}
                      alt="something here"
                      variant="rounded"
                      shadow="md"
                    />
                  </SoftBox>
                  <SoftBox
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="center"
                  >
                    <SoftTypography variant="button" fontWeight="medium">
                      Bob Allison
                    </SoftTypography>
                    <SoftTypography variant="caption" color="text">
                      VogueVenture
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>
                <SoftBox pt={2} px={2}>
                  <SoftTypography variant="h5" mb={3}>
                    "Before we began working with Cimpress Technology, it would
                    take us weeks to establish a business relationship with a
                    new manufacturing partner. Now we can do it in real-time,
                    from start to finish, before we even complete our first
                    discovery call with them."
                  </SoftTypography>
                  <SoftTypography variant="h4" style={textStyle}>
                    - National Pen
                  </SoftTypography>
                </SoftBox>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <Card>
                <SoftBox
                  component="li"
                  display="flex"
                  alignItems="center"
                  py={1}
                  mb={1}
                >
                  <SoftBox mr={2}>
                    <SoftAvatar
                      src={image}
                      alt="something here"
                      variant="rounded"
                      shadow="md"
                    />
                  </SoftBox>
                  <SoftBox
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="center"
                  >
                    <SoftTypography variant="button" fontWeight="medium">
                      Anne Marie
                    </SoftTypography>
                    <SoftTypography variant="caption" color="text">
                      Chocolate Inn
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>
                <SoftBox pt={2} px={2}>
                  <SoftTypography variant="h5" mb={3}>
                    "The Design Error Review solution is a lifesaver for
                    Vistaprint. By tackling the 6 most common DIY design errors,
                    we've seen a marked reduction in customer complaints,
                    translating to real dollars saved. Customers are happy with
                    the quality of the items they've ordered."
                  </SoftTypography>
                  <SoftTypography variant="h4" style={textStyle}>
                    - Vista
                  </SoftTypography>
                </SoftBox>
              </Card>
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}
