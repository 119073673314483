import { RefreshRounded, Settings } from "@mui/icons-material";
import { AppBar, Card, Divider, Tab, Tabs } from "@mui/material";
import Icon from "@mui/material/Icon";
import reload from "assets/images/icons8-refresh-30.svg";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "./components/DashboardNavbar";
import Filter from "./components/filterform";
import authorsTableData from "layouts/Orders/components/data/authorsTableData";
import projectsTableData from "layouts/Orders/components/data/projectsTableData";
import Table from "examples/Tables/Table";

const flexStyle = {
  display: "flex",
  //   "justify-content": "center"
};

export default function Order() {
  const { columns, rows } = authorsTableData;
  const { columns: prCols, rows: prRows } = projectsTableData;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        py={3}
        px={3}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <SoftBox>
          <SoftTypography variant="h2" fontWeight="bold">
            Orders {/* <Icon> */}
            <SoftButton
              variant="text"
              style={{ alignItems: "left", "margin-left": "-1em" }}
              size="small"
            >
              <img src={reload}></img>
            </SoftButton>
            {/* </Icon> */}
          </SoftTypography>
        </SoftBox>
        <SoftBox display="flex" flexDirection="row">
          <SoftBox ml={4} bgColor="dark">
            <SoftButton>Export</SoftButton>
          </SoftBox>
          <SoftBox ml={4} bgColor="dark">
            <SoftButton>Import</SoftButton>
          </SoftBox>
          <SoftBox ml={4} bgColor="dark">
            <SoftButton> New Order</SoftButton>
          </SoftBox>
        </SoftBox>
      </SoftBox>
      <Divider />

      <SoftBox mb={3}>
        <Card>
          <SoftBox
            pt={2}
            px={2}
            style={{
              display: "flex",
              "justify-content": "flex-start",
            }}
          >
            <a style={{ "margin-right": "1em" }}>
              <SoftTypography variant="h6">All</SoftTypography>
            </a>
            <a style={{ "margin-right": "1em" }}>
              {" "}
              <SoftTypography variant="h6">Pending Approval</SoftTypography>
            </a>
            <a style={{ "margin-right": "1em" }}>
              {" "}
              <SoftTypography variant="h6">Not Connected</SoftTypography>
            </a>
            <a style={{ "margin-right": "1em" }}>
              {" "}
              <SoftTypography variant="h6">On Hold</SoftTypography>
            </a>
            <a style={{ "margin-right": "1em" }}>
              {" "}
              <SoftTypography variant="h6">Failed</SoftTypography>
            </a>
            <a style={{ "margin-right": "1em" }}>
              {" "}
              <SoftTypography variant="h6">Draft</SoftTypography>
            </a>
            <a style={{ "margin-right": "1em" }}>
              {" "}
              <SoftTypography variant="h6">Passed</SoftTypography>
            </a>
            <a style={{ "margin-right": "1em" }}>
              {" "}
              <SoftTypography variant="h6">In Production</SoftTypography>
            </a>
            <a style={{ "margin-right": "1em" }}>
              {" "}
              <SoftTypography variant="h6">Printed</SoftTypography>
            </a>
            <a style={{ "margin-right": "1em" }}>
              {" "}
              <SoftTypography variant="h6">Shipped</SoftTypography>
            </a>
            <a style={{ "margin-right": "1em" }}>
              {" "}
              <SoftTypography variant="h6">In Transit</SoftTypography>
            </a>
            <a style={{ "margin-right": "1em" }}>
              {" "}
              <SoftTypography variant="h6">Delivered</SoftTypography>
            </a>
            <a style={{ "margin-right": "1em" }}>
              {" "}
              <SoftTypography variant="h6">Returned</SoftTypography>
            </a>
            <a style={{ "margin-right": "1em" }}>
              {" "}
              <SoftTypography variant="h6">Digitizing</SoftTypography>
            </a>
          </SoftBox>
          <Divider />
          <Filter />
        </Card>
      </SoftBox>
      <SoftBox py={3}>
        <Card>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            <SoftTypography variant="h6">Results</SoftTypography>
          </SoftBox>
          <SoftBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            <Table columns={prCols} rows={prRows} />
          </SoftBox>
        </Card>
      </SoftBox>
    </DashboardLayout>
  );
}
