import { Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";

function Templates() {

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox>
          <SoftTypography variant="h2" fontWeight="bold">
            Templates
          </SoftTypography>
        </SoftBox>

        <SoftBox py={3}>
          <SoftTypography variant="h5" fontWeight="medium">
            Save time by creating and using product templates
          </SoftTypography>
          <SoftBox py={6}>
            <Grid container spacing={3} lg={20}>
              <Grid item md={12} xl={12}>
                <SoftBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
                  <SoftButton color="white">Create a template</SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </DashboardLayout>
  );
}

export default Templates;
